import { Component, Input, inject, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NzProgressComponent } from 'ng-zorro-antd/progress';

import { type Task, type TaskSearchModel } from '@clover/tasks/models/task';
import { TaskHistoryModalComponent } from '@clover/tasks-history/task-history-modal/task-history-modal.component';
import { FocusableDirective } from '@core/directives/focusable.directive';
import { percentageFormatter } from '@core/helpers/nz-progress-formatters';
import { type TableConfig } from '@core/models/table';
import { ModalService } from '@core/services/modal.service';
import { StringHelpersService } from '@core/services/string-helpers.service';

import { TableComponent } from '../../../core/components/table/table.component';
import { UserLogoComponent } from '../../../core/components/user-logo/user-logo.component';
import { XMoreComponent } from '../../../core/components/x-more/x-more.component';
import { XMoreUsersComponent } from '../../../core/components/x-more-users/x-more-users.component';
import { DateFormatDistancePipe, DateFormatPipe } from '../../../core/pipes/date-format.pipe';
import { Product } from '../../models/product';

@Component({
  selector: 'product-task-history',
  templateUrl: './product-task-history.component.html',
  styleUrls: ['./product-task-history.component.scss'],
  standalone: true,
  imports: [
    TableComponent,
    NzProgressComponent,
    FocusableDirective,
    UserLogoComponent,
    XMoreUsersComponent,
    XMoreComponent,
    TranslateModule,
    DateFormatDistancePipe,
    DateFormatPipe,
  ],
})
export class ProductTaskHistoryComponent implements OnInit {
  public readonly stringHelpers = inject(StringHelpersService);
  private readonly modalService = inject(ModalService);
  @Input() product: Product;

  public data: Task[] = [];

  public tableConfig: TableConfig;
  protected readonly percentageFormatter = percentageFormatter;
  private readonly progressColors = {
    bad: '#F22206',
    medium: '#F2994A',
    good: '#27AE60',
  };

  public ngOnInit(): void {
    this.tableConfig = {
      loadUrl: `api/companies/my/products/${this.product.id}/tasks/search`,
      loadLimit: 10,
      defaultSort: 'createdAt',
      defaultSortDirection: 'desc',
      filters: [
        {
          name: 'taskType',
          queryParam: 'taskType',
          defaultValue: 'All',
          options: [
            {
              label: 'tasksPage.taskTypes.all',
              value: 'All',
            },
            {
              label: 'tasksPage.taskTypes.completed',
              value: 'Completed',
            },
            {
              label: 'tasksPage.taskTypes.incomplete',
              value: 'Incomplete',
            },
            {
              label: 'tasksPage.taskTypes.closed',
              value: 'Closed',
            },
          ],
        },
      ],
      columns: [
        {
          name: 'name',
          label: 'tasksPage.columns.task',
          sortable: true,
          minWidth: '300px',
          maxWidth: '35fr',
        },
        {
          name: 'assignedTo',
          label: 'tasksPage.columns.assignedTo',
          sortable: false,
          minWidth: '250px',
          maxWidth: '25fr',
        },
        {
          name: 'createdAt',
          label: 'tasksPage.columns.assigned',
          sortable: true,
          minWidth: '150px',
          maxWidth: '15fr',
        },
      ],
    };
  }

  public openTaskHistoryModal(task: TaskSearchModel): void {
    this.modalService.open({
      content: TaskHistoryModalComponent,
      inputs: {
        task,
      },
    });
  }

  public color(percent: number): string {
    if (percent > 70) {
      return this.progressColors.good;
    }

    if (percent > 35) {
      return this.progressColors.medium;
    }

    return this.progressColors.bad;
  }
}
