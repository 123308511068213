import { Injectable, inject } from '@angular/core';
import { type ActivatedRouteSnapshot, type RouterStateSnapshot } from '@angular/router';
import { type Observable } from 'rxjs';

import { ProductsService } from '../products.service';

@Injectable({
  providedIn: 'root',
})
export class ProductEditRouterResolver {
  private readonly productsService = inject(ProductsService);

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return this.productsService.getProduct(route.params.id);
  }
}
