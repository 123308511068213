import { Component, ViewChild, inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

import {
  RichTextEditorComponent,
  RichTextEditorComponent as RichTextEditorComponent_1,
} from '@core/components/rich-text-editor/rich-text-editor.component';
import { FocusableDirective } from '@core/directives/focusable.directive';
import { OkCancelModalComponent } from '@core/modals/ok-cancel-modal/ok-cancel-modal.component';
import { type TableConfig } from '@core/models/table';
import { type UploadedImageData } from '@core/models/user';
import { ModalService } from '@core/services/modal.service';
import { type UserPermissions } from '@core/services/permission.service';
import { ToastrService } from '@core/services/toastr.service';
import { UserService } from '@core/services/user.service';

import { NumberFormatPipe } from '../../core/pipes/number-format.pipe';
import { CompanyFilesComponent } from '../../network/components/company-files/company-files.component';
import { ProductImagesComponent } from '../components/product-images/product-images.component';
import { ProductTaskHistoryComponent } from '../components/product-task-history/product-task-history.component';
import { CantDeleteProductModalComponent } from '../modals/cant-delete-product-modal/cant-delete-product-modal.component';
import { type Product } from '../models/product';
import { ProductsService } from '../products.service';

@UntilDestroy()
@Component({
  selector: 'app-product-view',
  templateUrl: './product-view.component.html',
  styleUrls: ['./product-view.component.scss'],
  standalone: true,
  imports: [
    FocusableDirective,
    ProductImagesComponent,
    RichTextEditorComponent_1,
    FormsModule,
    CompanyFilesComponent,
    ProductTaskHistoryComponent,
    TranslateModule,
    NumberFormatPipe,
  ],
})
export class ProductViewComponent implements OnInit {
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly userService = inject(UserService);
  private readonly productsService = inject(ProductsService);
  private readonly modalService = inject(ModalService);
  private readonly toastr = inject(ToastrService);
  @ViewChild('marketingMessage') marketingMessage: RichTextEditorComponent;

  public product: Product;
  public images: UploadedImageData[] = [null, null, null, null];
  public marketingMsg: any;
  public filesTableConfig: TableConfig;
  public isLoading = false;

  public tabs = {
    basic: 'basic',
    files: 'files',
    taskHistory: 'taskHistory',
  };

  public activeTab = 'basic';

  public filesUrl: string;
  public description = '';

  public get permissions(): UserPermissions {
    return this.userService.permissions;
  }

  public getDescriptionString(): string {
    if (!this.product?.marketingMessage) {
      return '';
    }

    try {
      const msgJson = JSON.parse(this.product.marketingMessage);
      const strings = msgJson?.ops.map((str) => str.insert.replace(/\n/, ''));
      return strings.join(' ');
    } catch (error) {
      return '';
    }
  }

  public ngOnInit(): void {
    this.product = this.route.snapshot.data.user;
    this.product.logos.forEach((data) => {
      this.images[data.slotId - 1] = data.logo;
    });

    let marketingMsg = null;

    try {
      marketingMsg = JSON.parse(this.product.marketingMessage);
    } catch (error) {}

    if (!marketingMsg && this.product.marketingMessage) {
      marketingMsg = this.product.marketingMessage;
    }

    if (this.product.marketingMessage && typeof marketingMsg === 'object') {
      this.marketingMsg = marketingMsg;
    } else {
      this.marketingMsg = {
        ops: [
          {
            insert: this.product.marketingMessage,
          },
        ],
        html: `<p>${this.product.marketingMessage}</p>`,
      };
    }

    this.filesUrl = `api/companies/my/products/${this.product.id}/files`;
    this.description = this.getDescriptionString();
  }

  public edit(): void {
    this.router.navigate([`/products/${this.product.id}/edit`]);
  }

  public delete(): void {
    this.productsService
      .getAssignedTasksToProduct(this.product.id)
      .pipe(
        untilDestroyed(this),
        map((res) => {
          if (res?.length > 0) {
            this.modalService.open({
              content: CantDeleteProductModalComponent,
              inputs: {
                product: this.product,
                taskNames: res.map((task) => task.name),
              },
            });
          } else {
            this.modalService
              .open({
                content: OkCancelModalComponent,
                inputs: {
                  title: 'productsPage.confirmDeletion',
                  text: 'productsPage.confirmDeletionMsg',
                  textParams: {
                    productName: this.product.name,
                  },
                },
              })
              .result.then(() => {
                this.productsService.deleteProduct(this.product.id).pipe(
                  untilDestroyed(this),
                  map(() => {
                    this.router.navigate([[`/products`]]);
                  }),
                  catchError((err) => {
                    this.toastr.displayServerErrors(err);
                    return of(err);
                  }),
                  finalize(() => (this.isLoading = false)),
                );
              })
              .catch(() => (this.isLoading = false));
          }
        }),
        catchError((err) => {
          this.toastr.displayServerErrors(err);
          return of(err);
        }),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe();
  }
}
