<div
  class="custom-selector"
  [attr.disabled]="disabled ? true : null"
>
  <div class="custom-selector_text">
    @if (value) {
      <span>{{ value.title }}</span>
    }
    @if (!value) {
      <span class="custom-selector_placeholder">
        {{ placeholder | translate }}
      </span>
    }
  </div>
  <div
    [appFocusable]
    class="custom-selector_button"
    (click)="openSelector()"
  >
    <img appAssetSrc="assets/svg/common/dropdown-ellipsis-white.svg" />
  </div>
</div>
