import { Injectable, inject } from '@angular/core';
import { type Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { HttpService } from '@core/services/http.service';
import { UserService } from '@core/services/user.service';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  private readonly http = inject(HttpService);
  private readonly userService = inject(UserService);

  public getProduct(id: number): Observable<any> {
    return this.http.getV2(`/api/companies/${this.userService.userProfile.companyId}/products/${id}`);
  }

  public createProduct(data: any): Observable<any> {
    return this.http.postV2(`/api/companies/${this.userService.userProfile.companyId}/products`, data);
  }

  public updateProduct(id: number, data: any): Observable<any> {
    return this.http.putV2(`/api/companies/${this.userService.userProfile.companyId}/products/${id}`, data);
  }

  public assignProductTasks(data: any): Observable<any> {
    return this.http.postV2(`api/sentCompanyTasks/my/products`, data);
  }

  public getWorklists(): Observable<any> {
    return this.http.getV2(`api/companies/my/productWorklists`);
  }

  public removeFromWorklist(worklistId: number, productIds: number[]): Observable<any> {
    const productIdsData = { deletedProductIds: productIds };
    return this.http.patchV2(`api/companies/my/productWorklists/${worklistId}`, productIdsData);
  }

  public updateWorklist(id: number, data: { name: string }): Observable<any> {
    return this.http.putV2(`api/companies/my/productWorklists/${id}`, data);
  }

  public deleteWorklist(id: number, moveToWorklistId?: number): Observable<any> {
    let url = `api/companies/my/productWorklists/${id}`;

    if (moveToWorklistId) {
      url += `?moveToWorklistId=${moveToWorklistId}`;
    }

    return this.http.deleteV2(url);
  }

  public deleteProduct(id: number): Observable<any> {
    return this.http.deleteV2(`/api/companies/${this.userService.userProfile.companyId}/products/${id}`);
  }

  public getAssignedTasksToProduct(id: number): Observable<any> {
    return this.http.getV2(`api/companies/my/products/${id}/tasks/search`).pipe(map((res: any) => res.data));
  }

  public getIncompleteTasksForProduct(id: number): Observable<any> {
    return this.http.getV2(`api/companies/my/products/${id}/tasks/search?taskType=Incomplete`).pipe(
      switchMap((res: any) => {
        if (res?.data?.length < res.total) {
          return this.http
            .getV2(`api/companies/my/products/${id}/tasks/search?taskType=Incomplete&limit=${res.total}`)
            .pipe(
              map((allTasksRes: any) => {
                return allTasksRes.data;
              }),
              catchError((err) => {
                return of(err);
              }),
            );
        } else {
          return of(res.data);
        }
      }),
      catchError((err) => {
        return of(err);
      }),
    );
  }

  public closeTasksForProduct(taskIds: number[]): Observable<any> {
    const taskIdsQueryParams = taskIds.map((id) => `taskIds=${id}`).join('&');
    return this.http.postV2(`api/sentCompanyTasks/close?${taskIdsQueryParams}`, {});
  }

  public getAllProductIds(companyId: number, filtersString?: string): Observable<any> {
    return this.http.getV2(`api/companies/${companyId}/products/ids${filtersString || ''}`);
  }
}
