import { NgClass } from '@angular/common';
import { Component, ViewEncapsulation, inject, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskDirective } from 'ngx-mask';
import { of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

import { FormErrorMessagesComponent } from '@core/components/form-error-messages/form-error-messages.component';
import { RichTextEditorComponent } from '@core/components/rich-text-editor/rich-text-editor.component';
import { type SelectOption } from '@core/components/select/select.component';
import { SelectComponent } from '@core/components/select/select.component';
import { ButtonLoadingDirective } from '@core/directives/button-disable.directive';
import { CompanyLicenseTier, ConnectionStatus } from '@core/models/company';
import { type UploadedImageData } from '@core/models/user';
import { EnumService } from '@core/services/enum.service';
import { LocalizationService } from '@core/services/localization.service';
import { ModalService } from '@core/services/modal.service';
import { ToastrService } from '@core/services/toastr.service';
import { UserService } from '@core/services/user.service';

import { CompanySelectorComponent } from '../../network/components/company-selector/company-selector.component';
import { ClassificationSelectorComponent } from '../components/classification-selector/classification-selector.component';
import { ProductImagesComponent } from '../components/product-images/product-images.component';
import { VendorEditModalComponent } from '../modals/vendor-edit-modal/vendor-edit-modal.component';
import { type Product } from '../models/product';
import { ProductsService } from '../products.service';

@UntilDestroy()
@Component({
  selector: 'app-products-edit',
  templateUrl: './product-edit.component.html',
  styleUrls: ['./product-edit.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    ProductImagesComponent,
    NgClass,
    FormErrorMessagesComponent,
    RichTextEditorComponent,
    SelectComponent,
    ClassificationSelectorComponent,
    CompanySelectorComponent,
    NgxMaskDirective,
    ButtonLoadingDirective,
    TranslateModule,
  ],
})
export class ProductEditComponent implements OnInit {
  private readonly router = inject(Router);
  private readonly enumService = inject(EnumService);
  private readonly toastr = inject(ToastrService);
  private readonly productService = inject(ProductsService);
  private readonly route = inject(ActivatedRoute);
  private readonly userService = inject(UserService);
  private readonly modalService = inject(ModalService);
  public readonly localization = inject(LocalizationService);
  public isLoading = false;
  public isEdit = false;
  public product: Product;
  public savedImages: UploadedImageData[] = [null, null, null, null];
  public unitsOfMeasure: SelectOption[] = [];
  public unitsOfMeasureTemperature: SelectOption[] = [];
  public accessTypes: SelectOption[] = [];
  public isFormSubmitted = false;
  public imagesError: string = null;
  public form: UntypedFormGroup;

  constructor() {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(null, [Validators.required]),
      marketingMessage: new UntypedFormControl(null),
      accessType: new UntypedFormControl(null),
      packSize: new UntypedFormControl(null),
      manufactureProductCode: new UntypedFormControl(null, []),
      brandName: new UntypedFormControl(null),
      internalItemNumber: new UntypedFormControl(null, [Validators.required]),
      globalTradeIdentificationNumber: new UntypedFormControl(null),
      globalProductClassification: new UntypedFormControl(null),
      vendor: new UntypedFormControl(null),
      productId: new UntypedFormControl(null),
      department: new UntypedFormControl(null),
      categoryCodes: new UntypedFormControl(null),
      length: new UntypedFormGroup({
        value: new UntypedFormControl('', []),
        unitOfMeasure: new UntypedFormControl(null, []),
      }),
      width: new UntypedFormGroup({
        value: new UntypedFormControl('', []),
        unitOfMeasure: new UntypedFormControl(null, []),
      }),
      height: new UntypedFormGroup({
        value: new UntypedFormControl('', []),
        unitOfMeasure: new UntypedFormControl(null, []),
      }),
      storageTemperatureMinimum: new UntypedFormGroup({
        value: new UntypedFormControl('', []),
        unitOfMeasure: new UntypedFormControl(null, []),
      }),
      storageTemperatureMaximum: new UntypedFormGroup({
        value: new UntypedFormControl('', []),
        unitOfMeasure: new UntypedFormControl(null, []),
      }),
    });

    this.vendorEditClick = this.vendorEditClick.bind(this);
  }

  get allowedVendorCompanyConnectionStatuses(): ConnectionStatus[] {
    return [ConnectionStatus.Connected, ConnectionStatus.Invited];
  }

  private _images: UploadedImageData[] = [null, null, null, null];

  public get images(): UploadedImageData[] {
    return this._images;
  }

  public set images(value: UploadedImageData[]) {
    this._images = value;
    if (this._images[0]) {
      this.imagesError = null;
    }
  }

  public get isIndividualCompany(): boolean {
    return this.userService.userCompany?.licenseTier === CompanyLicenseTier.Individual;
  }

  public ngOnInit(): void {
    this.enumService.getLinearUnits().then((res) => (this.unitsOfMeasure = res));

    this.enumService.getTemperatureUnits().then((res) => (this.unitsOfMeasureTemperature = res));

    this.enumService.getProductAccessTypes().then((res) => {
      this.accessTypes = res;
      if (!this.isEdit) {
        this.form.get('accessType').setValue(this.accessTypes[0]);
      }
    });

    if (this.router.url.includes('edit')) {
      this.isEdit = true;
      this.product = this.route.snapshot.data.user;

      let marketingMsg = null;

      try {
        marketingMsg = JSON.parse(this.product.marketingMessage);
      } catch (error) {}

      if (this.product.marketingMessage && (!marketingMsg || typeof marketingMsg !== 'object')) {
        marketingMsg = {
          ops: [
            {
              insert: this.product.marketingMessage,
            },
          ],
          html: `<p>${this.product.marketingMessage}</p>`,
        };
      }

      const formValue = {
        name: this.product.name || null,
        marketingMessage: marketingMsg || null,
        packSize: this.product.packSize || null,
        manufactureProductCode: this.product.manufactureProductCode || null,
        brandName: this.product.brandName || null,
        internalItemNumber: this.product.internalItemNumber || null,
        globalTradeIdentificationNumber: this.product.globalTradeIdentificationNumber,
        globalProductClassification: this.product.globalProductClassification?.category,
        vendor: this.product.vendor,
        length: this.product.length || { value: null, unitOfMeasure: null },
        width: this.product.width || { value: null, unitOfMeasure: null },
        height: this.product.height || { value: null, unitOfMeasure: null },
        storageTemperatureMaximum: this.product.storageTemperatureMaximum || {
          value: null,
          unitOfMeasure: null,
        },
        storageTemperatureMinimum: this.product.storageTemperatureMinimum || {
          value: null,
          unitOfMeasure: null,
        },
        accessType: this.product.accessType,
        productId: this.product.productId,
        department: this.product.department,
        categoryCodes: this.product.categoryCodes,
      };

      this.product.logos.forEach((data) => {
        this.images[data.slotId - 1] = data.logo;
        this.savedImages[data.slotId - 1] = data.logo;
      });

      if (this.isEdit && !this.userService.permissions.Product_EditVendor) {
        this.form.get('vendor').disable();
      }

      this.form.patchValue(formValue);
    }
  }

  public back(): void {
    if (this.isEdit) {
      this.router.navigate([`products/${this.product.id}`]);
    } else {
      this.router.navigate(['/products-old']);
    }
  }

  public save(): void {
    this.isFormSubmitted = true;

    if (this.form.invalid) {
      document.querySelector('form .ng-invalid').scrollIntoView();
      return;
    }

    const data = this.form.value;

    if (!data.marketingMessage) {
      data.marketingMessage = {
        ops: [
          {
            insert: '',
          },
        ],
        html: `<p></p>`,
      };
    }

    data.logoUpdates = this.getImageUpdates();
    data.accessTypeKey = data.accessType.value;
    data.marketingMessage = JSON.stringify(data.marketingMessage);
    data.length = this.convertOptionToModel(data.length);
    data.width = this.convertOptionToModel(data.width);
    data.height = this.convertOptionToModel(data.height);
    data.storageTemperatureMaximum = this.convertOptionToModel(data.storageTemperatureMaximum);
    data.storageTemperatureMinimum = this.convertOptionToModel(data.storageTemperatureMinimum);
    data.vendor = this.form.get('vendor').value;

    this.cleanData(data);

    const vendorId = data?.vendor?.id || data?.vendor?.companyId;
    if (vendorId) {
      data.vendorCompanyId = vendorId;
    }

    const promise = this.isEdit
      ? this.productService.updateProduct(this.product.id, data)
      : this.productService.createProduct(data);

    this.isLoading = true;
    promise
      .pipe(
        untilDestroyed(this),
        map((res) => {
          this.router.navigate([`products/${res.id}`]);
        }),
        catchError((err) => {
          this.toastr.displayServerErrors(err);
          return of(err);
        }),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe();
  }

  public vendorEditClick(openModalFunc: () => {}): void {
    const vendor = this.form.get('vendor').value;
    if (!this.isEdit || !vendor) {
      openModalFunc();
      return;
    }

    this.isLoading = true;
    this.productService
      .getIncompleteTasksForProduct(this.product.id)
      .pipe(
        untilDestroyed(this),
        map((tasks) => {
          if (tasks && tasks.length === 0) {
            openModalFunc();
            return;
          }

          this.modalService
            .open({
              content: VendorEditModalComponent,
              inputs: {
                tasks,
              },
            })
            .result.then(() => {
              openModalFunc();
            })
            .catch(() => {});
        }),
        catchError((err) => {
          this.toastr.displayServerErrors(err);
          return of(err);
        }),
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe();
  }

  private getImageUpdates(): any {
    return this.images
      .map((img, index) => {
        if (img === this.savedImages[index]) {
          return null;
        }

        if (!img) {
          return !this.isEdit
            ? null
            : {
                slotId: index + 1,
                logo: null,
              };
        }

        return {
          slotId: index + 1,
          logo: img,
          type: index === 0 ? 'Marketing' : 'Item',
        };
      })
      .filter((logoData) => !!logoData);
  }

  private cleanData(data: any): void {
    if (!data.width?.value) {
      delete data.width;
    }

    if (!data.height?.value) {
      delete data.height;
    }

    if (!data.length?.value) {
      delete data.length;
    }

    if (!data.storageTemperatureMaximum?.value) {
      delete data.storageTemperatureMaximum;
    }

    if (!data.storageTemperatureMinimum?.value) {
      delete data.storageTemperatureMinimum;
    }

    if (!data.marketingMessage) {
      delete data.marketingMessage;
    }
  }

  private convertOptionToModel(option: any): any {
    return (
      option && {
        value: option.value,
        unitOfMeasure: option?.unitOfMeasure && {
          key: option.unitOfMeasure?.value,
          title: option.unitOfMeasure?.label,
        },
      }
    );
  }
}
