<div class="modal-body">
  <h1>{{ 'vendorEditModal.confirmClosing' | translate }}</h1>

  <div>{{ 'vendorEditModal.cantChangeVendor' | translate }}</div>
  <ul>
    @for (task of tasks; track task) {
      <li>
        {{ task.name }}
      </li>
    }
  </ul>
  <div>{{ 'vendorEditModal.youCanCloseTasks' | translate }}</div>

  <div class="buttons-group">
    <button
      class="btn btn-primary decline-btn"
      (click)="activeModal.dismiss()"
    >
      {{ 'common.buttons.cancel' | translate }}
    </button>
    <button
      [appBtnLoading]="isLoading"
      (click)="closeTasks()"
      class="btn btn-primary submit-btn"
    >
      {{ 'vendorEditModal.closeTasks' | translate }}
    </button>
  </div>
</div>
